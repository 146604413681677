<template>
  <div>
    <div
      class="mb-4 text-subtitle-1 text--primary"
      >
      Para comenzar a operar en PartnersFans es necesario que te des de alta como cliente en nuestra pasarela de pago, Payku. Para ello, te vamos a solicitar que ingreses la información mínima y necesaria para poder generar el alta.
    </div>

    <v-alert
      type="success"
      prominent
      outlined
      border="left"
      v-if="paymentMethod.preferences.id && !editing"
      >
      <v-row
        align="center"
        >
        <v-col
          class="grow"
          >
          Ya te has vinculado exitosamente con Payku y puedes recibir pagos normalmente.
        </v-col>

        <v-col
          class="shrink"
          >
          <v-btn
            color="primary"
            @click="editing = true"
            class="rounded-lg"
            >
            Editar
          </v-btn>
        </v-col>
      </v-row>
    </v-alert>

    <template
      v-else
      >
      <v-card
        color="#e4e4e4"
        rounded="lg"
        elevation="6"
        v-if="!currentUser.selectedProfile.active && currentUser.selectedProfile.siblingActive"
        >
        <v-card-text
          class="text--primary body-1 d-flex flex-row justify-space-between align-center"
          >
          <div>
            Parece que ya tienes una tienda en PartnersFans, ¿quieres utilizar la misma información de cobro en esta tienda o prefieres hacer un alta como cliente nuevo?
          </div>

          <v-btn
            color="primary"
            class="rounded-lg"
            @click="$emit('clone')"
            >
            Utilizar misma info
          </v-btn>
        </v-card-text>
      </v-card>

      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
        >
        <div
          class="my-4 text-subtitle-1 text--primary"
          >
          Datos personales
        </div>

        <v-row>
          <v-col
            cols="12"
            md="6"
            >
            <v-text-field
              v-model="attributes.email"
              outlined
              label="E-mail"
              hide-details
              :rules="[ v => !!v || 'El campo es obligatorio']"
              ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="6"
            >
            <v-text-field
              v-model="attributes.name"
              outlined
              label="Nombre y apellido"
              hide-details
              :rules="[ 
              v => !!v || 'El campo es obligatorio',
              v => ((v || '').match(/\b(\w+)\b/g) || []).length > 1 || 'Debe contener nombre y apellido'
              ]"
              ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="6"
            >
            <v-text-field
              v-model="attributes.phone"
              outlined
              label="Teléfono"
              hide-details
              :rules="[ 
              v => !!v || 'El campo es obligatorio',
              ]"
              ></v-text-field>
          </v-col>
        </v-row>

        <div
          class="my-4 text-subtitle-1 text--primary"
          >
          Datos bancarios
        </div>

        <v-row>
          <v-col
            cols="12"
            md="6"
            >
            <v-autocomplete
              v-model="attributes.bank.sbif"
              outlined
              :items="banks"
              label="Banco"
              hide-details
              :rules="[ 
              v => !!v || 'El campo es obligatorio',
              ]"
              ></v-autocomplete>
          </v-col>

          <v-col
            cols="12"
            md="6"
            >
            <v-autocomplete
              v-model="attributes.bank.type"
              outlined
              :items="accountTypes"
              label="Tipo de cuenta"
              hide-details
              :rules="[ 
              v => !!v || 'El campo es obligatorio',
              ]"
              ></v-autocomplete>
          </v-col>

          <v-col
            cols="12"
            md="6"
            >
            <v-text-field
              v-model="attributes.bank.num"
              outlined
              label="Número de cuenta"
              hide-details
              :rules="[ 
              v => !!v || 'El campo es obligatorio',
              ]"
              ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="6"
            >
            <v-text-field
              v-model="attributes.bank.rut"
              outlined
              label="RUT"
              hide-details
              :rules="[ 
              v => !!v || 'El campo es obligatorio',
              ]"
              ></v-text-field>
          </v-col>
        </v-row>
      </v-form>

      <div
        class="text-right mt-4"
        >
        <v-btn
          color="primary"
          depressed
          class="rounded-lg"
          @click="createClient"
          :loading="loading"
          :disabled="loading"
          >
          {{ editing ? 'Guardar cambios' : 'Darme de alta' }}
        </v-btn>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters (['currentUser'])
  },

  props: {
    paymentMethod: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    valid: true,
    editing: false,
    loading: false,
    attributes: {
      email: null,
      name: null,
      phone: null,
      bank: {
        sbif: null,
        type: "1",
        num: null,
        rut: null
      }
    },
    banks: [
      {
        value: "0001",
        text: "Banco de Chile"
      },
      {
        value: "0009",
        text: "Banco Internacional"
      },
      {
        value: "0012",
        text: "Banco Estado"
      },
      {
        value: "0014",
        text: "Scotiabank Chile"
      },
      {
        value: "0016",
        text: "Banco de crédito e inversiones (BCI)"
      },
      {
        value: "0027",
        text: "Banco Corpbanca",
      },
      {
        value: "0028",
        text: "Banco Bice"
      },
      {
        value: "0031",
        text: "HSBC Bank Chile"
      },
      {
        value: "0037",
        text: "Banco Santander Chile"
      },
      {
        value: "0039",
        text: "Itaú Corpbanca"
      },
      {
        value: "0049",
        text: "Banco Security"
      },
      {
        value: "0051",
        text: "Banco Falabella"
      },
      {
        value: "0053",
        text: "Banco Ripley"
      },
      {
        value: "0054",
        text: "Rabobank Chile"
      },
      {
        value: "0055",
        text: "Banco Consorcio"
      },
      {
        value: "0056",
        text: "Banco Penta"
      },
      {
        value: "0057",
        text: "Banco Paris"
      },
      {
        value: "0059",
        text: "Banco BTG Pactual Chile"
      },
      {
        value: "0504",
        text: "Banco Bilbao Vizcaya Argentaria Chile (BBVA)"
      },
      {
        value: "0672",
        text: "Coopeuch"
      },
      {
        value: "729",
        text: "Caja los Andes"
      },
      {
        value: "730",
        text: "Tenpo"
      },
      {
        value: "732",
        text: "Los Andes"
      },
      {
        value: "875",
        text: "Mercado Pago"
      },
    ],
    accountTypes: [
      {
        value: "1",
        text: "Corriente",
      },
      {
        value: "2",
        text: "Vista / Cuenta RUT"
      },
      {
        value: "3",
        text: "Ahorro"
      }
    ],
  }),

  methods: {
    dec2hex (dec) {
      return dec.toString(16).padStart(2, "0")
    },

    generateId (len) {
      var arr = new Uint8Array((len || 40) / 2)
      window.crypto.getRandomValues(arr)
      return Array.from(arr, this.dec2hex).join('')
    },

    async createClient () {
      this.loading = true

      if (this.editing) {
        await fetch(`${process.env.VUE_APP_PAYKU_BASE_URL}/maclient/${this.paymentMethod.preferences.id}`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${process.env.VUE_APP_PAYKU_PUBLIC_TOKEN}`
          },
        })
      }

      const response = await fetch(`${process.env.VUE_APP_PAYKU_BASE_URL}/maclient`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${process.env.VUE_APP_PAYKU_PUBLIC_TOKEN}`
        },
        body: JSON.stringify(this.attributes)
      });
      const result = await response.json();

      this.createAffiliation (result.id)
    },

    async createAffiliation (name) {
      let data = {
        name: this.generateId(30),
        percentage: "0",
        affiliation: [
          [name, "100"]
        ]
      };

      const response = await fetch(`${process.env.VUE_APP_PAYKU_BASE_URL}/maaffiliation`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${process.env.VUE_APP_PAYKU_PUBLIC_TOKEN}`
        },
        body: JSON.stringify(data)
      });

      const result = await response.json();
      this.editing = false
      this.loading = false
      this.$emit('success', result.token)
    },
  }
}
</script>
